import React from "react"
import Layout from "../../components/Layout"
import SEO from "../../components/seo"

import * as S from "../../components/Pages/general"

const TutoriaisPage = () => {
  return (
    <Layout>
      <SEO title="Tutoriais - guias de como desenhar" description="" />
      <S.GeneralWrapper>
        <S.GeneralTitle>Tutorial </S.GeneralTitle>
        <S.GeneralLink to="./como-desenhar-olhos">
          Como desenhar olhos
        </S.GeneralLink>
      </S.GeneralWrapper>
    </Layout>
  )
}

export default TutoriaisPage
